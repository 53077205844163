import React, { Component } from 'react';

import {
  procedureRetrieve,
  procedureStepStoreTree
} from '../../actions/procedure/procedureStepStoreTree';
import { create as createProcedureStep } from '../../actions/procedurestep/create';
import { del as delProcedureStep } from '../../actions/procedurestep/delete';
import TreeFormFields from '../tree/TreeFormFields';

class ProcedureStepTreeFormFields extends Component {
  preventFormSubmit = event => {
    return this.getTreeComponent().preventFormSubmit(event);
  };

  attachTree = node => {
    this._procedureStepTree = node;
  };

  getTreeComponent = () => {
    return this._procedureStepTree;
  };

  hasTreeData = data => {
    if (data && data.procedureSteps) {
      return true;
    }

    return false;
  };

  getTreeData = data => {
    if (this.hasTreeData(data)) {
      return data.procedureSteps;
    }

    return [];
  };

  getInitialItemCreationData = objectId => {
    return { name: '', procedure: objectId };
  };

  actionCreateItem = values => {
    return createProcedureStep(values);
  };

  actionRetrieveItem = id => {
    return procedureRetrieve(id);
  };

  actionDeleteItem = (item, index) => {
    return delProcedureStep(item);
  };

  actionStoreTree = values => {
    if (values.returnObject !== 'manual_submit') {
      return dispatch => {
        return new Promise((resolve, reject) => {
          resolve({});
        });
      };
    }

    values.returnObject = true;
    return procedureStepStoreTree(values);
  };

  render() {
    return (
      <TreeFormFields
        initialValues={this.props.initialValues}
        change={this.props.change}
        dispatch={this.props.dispatch}
        getButtonComponent={this.props.getButtonComponent}
        allowAutoSave={this.props.allowAutoSave}
        hasFirstItem={this.props.hasFirstItem}
        setHasFirstItem={this.props.setHasFirstItem}
        hasTreeData={this.hasTreeData}
        getTreeData={this.getTreeData}
        getInitialItemCreationData={this.getInitialItemCreationData}
        actionCreateItem={this.actionCreateItem}
        actionRetrieveItem={this.actionRetrieveItem}
        actionDeleteItem={this.actionDeleteItem}
        actionStoreTree={this.actionStoreTree}
        fieldIdPrefix={'procedure'}
        fieldName={'procedureSteps'}
        addElementId={'add-procedure-step'}
        addElementLabel={'Add a Step'}
        displayAddButton={true}
        displayAddButtonOnTop={false}
        deleteItemConfirmMessage={'Are you sure you want to delete this step?'}
        deleteMultipleItemConfirmMessage={
          'Are you sure you want to delete this step and all its sub-steps?'
        }
        fieldWrapperClassName={'procedure-step'}
        firstFieldPlaceholder={"What's the first step of your procedure?"}
        fieldPlaceholder={
          "What's the next step? (Press ᴛᴀʙ to create a sub-step.)"
        }
        // isFirstField={
        //   document.getElementsByClassName('procedure-step').length < 1
        // }
        displayDeleteItemButton={true}
        displaySelectItemButton={'full_width'}
        getSelectItemActionComponent={this.props.getSelectItemActionComponent}
        getDeleteItemActionComponent={this.props.getDeleteItemActionComponent}
        disableDeleteDefaultConfirmation={
          this.props.disableDeleteDefaultConfirmation
        }
        itemWidth={1040 + 20}
        iconParentOpenClass={'fa fa-chevron-down'}
        iconParentClosedClass={'fa fa-chevron-right'}
        isDraggingEnabled={true}
        getFieldValueAtIndex={this.props.getProcedureStepNameAtIndex}
        ref={this.attachTree.bind(this)}
      />
    );
  }
}

export default ProcedureStepTreeFormFields;
