import React, { Component } from 'react';

import { fetch } from '../utils/dataAccess';

class SecureImage extends Component {
  zoom = this.props.zoom
    ? this.props.zoom.clone({
        background: this.props.background
      })
    : {};

  attachZoom = image => {
    if (typeof this.zoom.attach === 'function') {
      this.zoom.attach(image);
    }
  };

  componentDidMount() {
    let file = this.props.file;

    let fileHdrs = new Headers();
    fileHdrs.set('Accept', file.filetype);
    fileHdrs.set('Content-Type', file.filetype);

    fetch(file.location + '/get', {
      headers: fileHdrs
    })
      .then(response => response.blob())
      .then(blob => {
        let reader = new FileReader();
        reader.addEventListener('load', () => {
          let elem = document.getElementById(this.props.id);
          if (elem) {
            elem.src = reader.result;
          }
        });
        reader.readAsDataURL(blob);
      });
  }

  render() {
    return (
      <img
        {...this.props.attributes}
        id={this.props.id}
        src={this.props.src}
        alt={this.props.alt}
        ref={this.attachZoom}
      />
    );
  }
}

export default SecureImage;
