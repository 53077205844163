import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { change as changeFormField, formValueSelector } from 'redux-form';

import { del, success as delSuccess } from '../../actions/procedure/delete';
import { exportPdfProcedure } from '../../actions/procedure/exportPdf';
import { reset, retrieve, update } from '../../actions/procedure/update';
import CategoryTreeSelectionForm from '../category/UpdateTree';
import Backdrop from '../drawer/Backdrop/Backdrop';
import SideDrawer from '../drawer/SideDrawer/SideDrawer';
import Form from './Form';

class Update extends Component {
  state = {
    procedureModals: {},
    sideDrawerOpen: false,
    redirectToList: false,
    isSticky: false
  };

  static propTypes = {
    retrieved: PropTypes.object,
    retrieveLoading: PropTypes.bool.isRequired,
    retrieveError: PropTypes.string,
    updateLoading: PropTypes.bool.isRequired,
    updateError: PropTypes.string,
    deleteLoading: PropTypes.bool.isRequired,
    deleteError: PropTypes.string,
    updated: PropTypes.object,
    deleted: PropTypes.object,
    eventSource: PropTypes.instanceOf(EventSource),
    retrieve: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired,
    del: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.retrieve(decodeURIComponent(this.props.match.params.id));
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
    window.removeEventListener('scroll', () => this.handleScroll);
  }

  handleScroll = () => {
    if (this._stickyHeader) {
      this.setState({
        isSticky: window.scrollY > 86
        //isSticky: this._stickyHeader.getBoundingClientRect().top <= 0
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (
      this.props.match.params.id &&
      ((this.props.updated &&
        this.props.updated['@id'] &&
        this.props.updated['@id'] !==
          decodeURIComponent(this.props.match.params.id)) ||
        (this.props.retrieved &&
          this.props.retrieved['@id'] &&
          this.props.retrieved['@id'] !==
            decodeURIComponent(this.props.match.params.id)))
    ) {
      return true;
    }

    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
  }

  attachNode = node => {
    this._form = node;
  };

  getFormComponent = () => {
    return this._form;
  };

  del = () => {
    if (window.confirm('Are you sure you want to delete this procedure?'))
      this.props.del(this.props.retrieved);
  };

  delNow = (item, event) => {
    event.target.disabled = true;
    event.target.innerHTML += '<span class="fa fa-spinner fa-spin"></span>';

    this.props.del(item);
  };

  setShow = (procedure, value) => {
    this.setState({
      procedureModals: {
        ...this.state.procedureModals,
        [procedure['@id']]: value
      }
    });
  };

  handleClose = procedure => this.setShow(procedure, false);

  handleShow = procedure => this.setShow(procedure, true);

  pressFormSubmit = () => {
    setTimeout(() => {
      this.setState({
        redirectToList: true
      });
    }, 100);
  };

  canRedirectToList = () => {
    if (this.state.redirectToList) {
      this.setState({
        redirectToList: false
      });

      return true;
    }

    return false;
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  selectRootItem = (event, data, component) => {
    //
  };

  selectTreeItem = (data, index, component) => {
    this.props.changeFormField('procedure', 'category', data['@id']);
    let formCategoryName = this.props.getCategoryNameAtIndex(index);
    let dataCategoryName = data['name'];
    let categoryName =
      formCategoryName && formCategoryName !== dataCategoryName
        ? formCategoryName
        : dataCategoryName;
    this.props.changeFormField('procedure', 'categoryName', categoryName);

    setTimeout(() => {
      let submitButton = document.getElementById('new-submit-button');
      if (submitButton) {
        submitButton.click(); // handleBlur
      }
      this.backdropClickHandler();
    }, 1000);

    // close the drawer quicker
    setTimeout(() => {
      this.backdropClickHandler();
    }, 100);
  };

  exportPdfProcedure = (item, event) => {
    event.preventDefault();
    this.props.exportPdfProcedure(item);
  };

  addProcedureStep = (item, event) => {
    event.preventDefault();
    let elem = document.getElementById('add-procedure-step');
    if (elem) {
      elem.click();
    }
  };

  render() {
    if (this.props.deleted) {
      this.props.delReset();
      return <Redirect to=".." />;
    }

    if (this.canRedirectToList()) {
      return <Redirect to=".." />;
    }

    const item =
      (this.props.updated &&
        this.props.updated['@id'] &&
        this.props.match.params.id &&
        this.props.updated['@id'] ===
          decodeURIComponent(this.props.match.params.id)) ||
      (this.props.updated &&
        this.props.retrieved &&
        this.props.updated['@id'] &&
        this.props.retrieved['@id'] &&
        this.props.updated['@id'] === this.props.retrieved['@id'])
        ? this.props.updated
        : this.props.retrieved;

    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    let lastSelectedCategoryId = false;
    if (item && item.category && item.category['@id']) {
      lastSelectedCategoryId = item.category['@id'].replace(/[^\d]*/, '');
    }

    return (
      <div className={'update-procedure mt-3'}>
        {/*
        <h1>Edit {item && item['@id']}</h1>
        */}

        {/*
        {this.props.created && (
          <div className="alert alert-success" role="status">
            {this.props.created['@id']} created.
          </div>
        )}
        */}
        {/*
        {this.props.created && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <i className="fa fa-check"></i>
              </div>
            </div>
          </div>
        )}
        */}
        {/*
        {this.props.updated && (
          <div className="alert alert-success" role="status">
            {this.props.updated['@id']} updated.
          </div>
        )}
        */}
        {/*
        {this.props.updated && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                <i className="fa fa-check"></i>
              </div>
            </div>
          </div>
        )}
        */}
        {/*
        {(this.props.retrieveLoading ||
          this.props.updateLoading ||
          this.props.deleteLoading) && (
          <div className="alert alert-info" role="status">
            Loading...
          </div>
        )}
        */}
        {(this.props.retrieveLoading ||
          // this.props.updateLoading ||
          this.props.deleteLoading) && (
          <div className="pull-right" role="status">
            <div style={{ position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0 }}>
                Loading...
              </div>
            </div>
          </div>
        )}
        {this.props.retrieveError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.retrieveError}
          </div>
        )}
        {this.props.updateError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.updateError}
          </div>
        )}
        {this.props.deleteError && (
          <div className="alert alert-danger" role="alert">
            <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
            {this.props.deleteError}
          </div>
        )}

        <div
          className={`form-actions-wrapper${
            this.state.isSticky ? ' yellow-sticky' : ''
          }`}
          ref={ref => (this._stickyHeader = ref)}
        >
          <div className={'form-actions'}>
            {/*
            <Link to=".." className="btn btn-primary pull-left">
              All Procedure Templates
            </Link>
            */}
            <button
              onClick={() =>
                this.handleShow({
                  '@id': 'delete_' + item['@id']
                })
              }
              className="btn btn-danger"
            >
              {/* Delete This Procedure */}
              Delete This Template
            </button>
            <Modal
              show={
                (item && this.state.procedureModals['delete_' + item['@id']]) ||
                false
              }
              onHide={() =>
                this.handleClose({
                  '@id': 'delete_' + item['@id']
                })
              }
              centered
              size="xl"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {/* Are you sure you want to delete this Procedure? */}
                  Are you sure you want to delete this Template?
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>This will delete:</p>
                <ul>
                  {/* <li>This Procedure</li>
                  <li>All Steps in this Procedure</li> */}
                  <li>This Template</li>
                  <li>All Steps in this Template</li>
                </ul>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className={'btn'}
                  onClick={() =>
                    this.handleClose({
                      '@id': 'delete_' + item['@id']
                    })
                  }
                >
                  Cancel
                </button>
                <button
                  className={'btn btn-danger'}
                  onClick={event => this.delNow(item, event)}
                >
                  {/* Delete Procedure */}
                  Delete Template
                </button>
              </Modal.Footer>
            </Modal>
            {/*
            <button
              onClick={() => window.print()}
              className="btn btn-info btn-yellow pull-left"
            >
              Print
            </button>
            */}
            {/*
            <button
              onClick={event => this.exportPdfProcedure(item, event)}
              className="btn btn-info btn-yellow pull-left"
            >
              Export PDF
            </button>
            */}
            {/*<button*/}
            {/*  onClick={event => this.addProcedureStep(item, event)}*/}
            {/*  className="btn btn-info btn-yellow"*/}
            {/*>*/}
            {/*  Add a Step*/}
            {/*</button>*/}
          </div>
        </div>

        {item && (
          <Form
            onSubmit={values => {
              this.props.update(item, values);

              const internalTree = this.getFormComponent()
                .ref?.wrappedInstance?.wrapped?.getTreeComponent()
                ?.getTreeComponent();
              const treeData = internalTree.getTreeComponentTree();
              internalTree.onTreeUpdated(treeData, 'manual_submit');
            }}
            initialValues={item}
            allowAutoSave={false}
            pressFormSubmit={this.pressFormSubmit}
            drawerToggleClickHandler={this.drawerToggleClickHandler}
            getProcedureStepNameAtIndex={this.props.getProcedureStepNameAtIndex}
            ref={this.attachNode.bind(this)}
          />
        )}

        {item && (
          <SideDrawer show={this.state.sideDrawerOpen}>
            <div className={'ml-2 mr-2'}>
              <CategoryTreeSelectionForm
                isDraggingEnabled={true}
                displayAddButton={true}
                displayEditItemButton={true}
                displayDeleteItemButton={true}
                displaySelectItemButton={true}
                lastSelectedFieldIndex={lastSelectedCategoryId}
                selectRootItem={this.selectRootItem}
                selectTreeItem={this.selectTreeItem}
                allowEnterKey={false}
              />
            </div>
          </SideDrawer>
        )}
        {backdrop}
      </div>
    );
  }
}

const procedureFormSelector = formValueSelector('procedure');
const categoryFormSelector = formValueSelector('category');

const mapStateToProps = state => ({
  getProcedureStepNameAtIndex: index =>
    procedureFormSelector(state, 'procedureSteps[' + index + '][name]'),
  getCategoryNameAtIndex: index =>
    categoryFormSelector(state, 'rootChildren[' + index + '][name]'),
  retrieved: state.procedure.update.retrieved,
  retrieveError: state.procedure.update.retrieveError,
  retrieveLoading: state.procedure.update.retrieveLoading,
  updateError: state.procedure.update.updateError,
  updateLoading: state.procedure.update.updateLoading,
  deleteError: state.procedure.del.error,
  deleteLoading: state.procedure.del.loading,
  eventSource: state.procedure.update.eventSource,
  created: state.procedure.create.created,
  deleted: state.procedure.del.deleted,
  updated: state.procedure.update.updated
});

const mapDispatchToProps = dispatch => ({
  exportPdfProcedure: item => dispatch(exportPdfProcedure(item)),
  delReset: () => dispatch(delSuccess(null)),
  changeFormField: (form, field, value) =>
    dispatch(changeFormField(form, field, value)),
  retrieve: id => dispatch(retrieve(id)),
  update: (item, values) => dispatch(update(item, values)),
  del: item => dispatch(del(item)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(mapStateToProps, mapDispatchToProps)(Update);
